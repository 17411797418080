<template>
  <section class="hero-image">
    <div class="hero-txt">
      <h1>{{ $t("home.saveMoney") }}</h1>
      <p>{{ $t("home.bookSmart") }}</p>
      <div class="great-offers">
        <b-form inline @submit.prevent="findPlaces">
          <b-input-group>
            <b-form-input
              id="inline-form-input-name"
              class="banner-input-rule"
              autocomplete="off"
              :placeholder="$t('home.goingWhere')"
              v-model="location.destination"
            ></b-form-input>
            <b-button type="submit" variant="primary" class="find">{{
              $t("home.findOffers")
            }}</b-button>
          </b-input-group>
        </b-form>
      </div>
    </div>
  </section>
</template>
<style>
.disabled {
  color: #ddd !important;
  cursor: default;
}
.pac-container:after {
  background-image: none !important;
  height: 0;
}
</style>
<script>
import { initPlaces, removeInstance } from "../helpers/map";

const getCurrentLocation = async () => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        () => {
          resolve({ latitude: null, longitude: null });
        }
      );
    } else {
      resolve({ latitude: null, longitude: null });
    }
  });
};

export default {
  name: "HeroSection",
  data() {
    return {
      location: {
        destination: "",
        latitude: 0,
        longitude: 0,
      },
    };
  },
  methods: {
    findPlaces() {
      let { latitude, longitude, destination } = this.location;
      if (latitude && longitude) {
        if (!destination) {
          destination = this.$t('home.nearYou');
        }
        this.$router.push({
          path: "listing",
          query: { latitude, longitude, destination },
        });
      }
    },
  },
  async mounted() {
    initPlaces("inline-form-input-name", ({ latitude, longitude, destination }) => {
      this.location.latitude = latitude;
      this.location.longitude = longitude;
      this.location.destination = destination;
    });
    const { latitude, longitude } = await getCurrentLocation();
    if (latitude && longitude) {
      this.location.latitude = latitude;
      this.location.longitude = longitude;
    } else {
      console.error("Unable to retrieve location.");
    }
  },
  beforeDestroy() {
    removeInstance();
  },
};
</script>
