<template>
  <ReservationCancellation></ReservationCancellation>
</template>
<script>
import ReservationCancellation from "../components/ReservationCancellation.vue";
export default {
  name: "HotelReservationCancellation",
  components: {
    ReservationCancellation,
  },
};
</script>
