<template>
  <div class="container-fluid plr-80 payment-page">
    <div class="offset-md-4 col-md-4">
      <h4 class="font-weight-bold text-center mb-2">
        {{ $t("reservation.bookingCancellation") }}
      </h4>
      <ValidationObserver ref="observer">
        <div class="mb-3">
          <label>{{ $t("reservation.bookingNumber") }}</label>
          <ValidatedInput
            name="Booking number"
            placeholder=""
            rules="required"
            border
            disabled
            :value="bookingDetails.bookingId"
          />
        </div>

        <div class="mb-3">
          <label>{{ $t("reservation.emailAddress") }}</label>
          <ValidatedInput
            name="Email address"
            placeholder=""
            rules="required|email"
            border
            disabled
            :value="bookingDetails.email"
          />
        </div>

        <div class="mb-3" v-if="bookingDetails.provider === 'cultswitch'">
          <label>{{ $t("reservation.cancelReason") }}</label>
          <ValidatedInput
            type="textarea"
            name="Reason"
            placeholder=""
            rules=""
            border
            v-model="reason"
          />
        </div>

        <div v-if="policies.length">
          <h6 class="font-weight-bold">
            {{ $t("property.cancellationPolicy") }}
          </h6>
          <ul class="p-0 pl-4">
            <li
              style="list-style: circle"
              v-for="policy in policies"
              :key="policy.id"
            >
              {{ policy.name }}
            </li>
          </ul>
        </div>

        <PrimaryButton
          class="mt-3"
          @click="cancel"
          :disabled="!bookingDetailsValid"
          :loading="cancelling"
          full
          :text="$t('reservation.cancelBooking')"
        />
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "ReservationCancellation",
  data() {
    return {
      cancelling: false,
      bookingDetails: {},
      policies: [],
      reason: "",
    };
  },
  components: {
    PrimaryButton,
  },
  computed: {
    bookingDetailsValid() {
      const { bookingId, email } = this.bookingDetails;
      return bookingId && email;
    },
  },
  methods: {
    ...mapActions("booking", ["cancelBooking", "getCancelPolicy"]),
    async cancel() {
      try {
        this.cancelling = true;
        if (this.bookingDetailsValid) {
          this.bookingDetails.reason = this.reason;
          await this.cancelBooking(this.bookingDetails);
          this.$toastr.s("Success", this.$t("reservation.cancelSuccess"));
          this.$router.push(`/listing/${this.bookingDetails.propertyId}`);
        }
      } catch (error) {
        this.$toastr.e(error.message);
      } finally {
        this.cancelling = false;
      }
    },
  },
  async mounted() {
    const { query } = this.$route;
    if (
      !query.provider ||
      !["cultswitch", "ehotel"].includes(query.provider) ||
      !query.propertyId
    ) {
      this.$router.push("/");
    }
    this.bookingDetails = query;
    if (query.provider === "cultswitch") {
      try {
        const { data } = await this.getCancelPolicy(query.bookingId);
        this.policies = data.policies;
      } catch (error) {
        this.$toastr.e(error.message);
      }
    } else {
      if (!query.bookingPin && !query.supplierUnitId) {
        this.$router.push("/");
      }
    }
  },
};
</script>
