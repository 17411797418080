<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }">
    <div>
      <input
        v-if="['text', 'number', 'email'].includes(type)"
        :class="[
          'form-control',
          { 'no-border': !border },
          { 'input-error': errors.length },
        ]"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        v-model="inputValue"
        @click="$emit('click')"
      />
      <vue-tel-input
        v-else-if="type == 'tel'"
        mode="international"
        :class="[
          'form-control',
          { 'no-border': !border },
          { 'input-error': errors.length },
        ]"
        v-model="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        :input-options="{ placeholder }"
        @country-changed="$emit('setCountry', $event)"
      />
      <textarea
        v-else-if="type == 'textarea'"
        :class="[
          'form-control',
          { 'no-border': !border },
          { 'input-error': errors.length },
        ]"
        :placeholder="placeholder"
        v-model="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        rows="7"
      ></textarea>
      <select
        v-else-if="type == 'country'"
        :class="[
          'form-control',
          { 'no-border': !border },
          { 'input-error': errors.length },
        ]"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        @change="setCompanyCountryCode"
      >
        <option value="">{{ $t("listing.country") }}</option>
        <option
          v-for="country in countryList"
          :key="country.name"
          :value="country.name"
        >
          {{ country.name }}
        </option>
      </select>
      <select
        v-else-if="type == 'single-select'"
        :class="[
          'form-control',
          { 'no-border': !border },
          { 'input-error': errors.length },
        ]"
        v-model="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
      >
        <option value="">{{ placeholder }}</option>
        <template v-if="isArrayOfObjects(options)">
          <option v-for="{ id, text } in options" :key="id" :value="id">
            {{ text }}
          </option>
        </template>
        <template v-else>
          <option v-for="option in options" :key="option" :value="option">
            {{ option }}
          </option>
        </template>
      </select>
      <span v-if="errors.length" class="text-danger small">{{
        errors[0]
      }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { countryList } from "../shared";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ValidatedInput",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    countryList: () => countryList,
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    isArrayOfObjects(array) {
      return (
        Array.isArray(array) &&
        array.every((item) => typeof item === "object" && item !== null)
      );
    },
    setCompanyCountryCode(e) {
      const value = e.target.value;
      if (value) {
        const country = this.countryList.find(({ name }) => name === value);
        this.$emit("setCountryCode", country?.code);
      }
    },
  },
};
</script>

<style scoped>
.input-error {
  border: 1px solid red !important;
}
@media screen and (max-width: 600px) {
  .form-control {
    font-size: 14px;
  }
}
</style>
