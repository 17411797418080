<template>
  <div class="container booking-request px-0 my-5">
    <ValidationObserver ref="observer">
      <h2 class="text-center mb-3">{{ $t("listing.bookingRequest") }}</h2>
      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4">{{ $t("listing.bookingDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="check-in date"
                class="check-in"
                @click="toggleCalendar()"
                readonly
                :value="formData.check_in"
                :placeholder="`${$t('listing.checkInDate')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                @click="toggleCalendar()"
                name="check-out date"
                class="check-out"
                readonly
                :value="formData.check_out"
                :placeholder="`${$t('listing.checkOutDate')}*`"
                rules="required"
              />
            </div>
            <input
              v-if="showCalendar"
              type="text"
              v-model="calendarValue"
              id="datePicker"
              style="opacity: 0"
            />
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="guests"
                type="number"
                v-model="formData.guests"
                :placeholder="`${$t('listing.noOfGuest')}*`"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4" ref="bookerDetails">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4">{{ $t("listing.yourDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="first name"
                v-model="formData.first_name"
                :placeholder="`${$t('listing.firstName')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="last name"
                v-model="formData.last_name"
                :placeholder="`${$t('listing.lastName')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="email"
                v-model="formData.email"
                :placeholder="`${$t('listing.email')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3 no-border">
              <ValidatedInput
                type="tel"
                name="phone"
                v-model="formData.phone"
                :placeholder="$t('reservation.placeholderPhone')"
                rules="required"
              />
            </div>
            <div class="col-md-4 my-3">
              <ValidatedInput
                name="post code"
                v-model="formData.post_code"
                :placeholder="`${$t('listing.postCode')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-4 my-3">
              <ValidatedInput
                name="city"
                v-model="formData.city"
                :placeholder="`${$t('listing.city')}*`"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4">{{ $t("listing.guestDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest first name"
                v-model="formData.guest_first_name"
                :placeholder="`${$t('listing.firstName')}*`"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest last name"
                v-model="formData.guest_last_name"
                :placeholder="`${$t('listing.lastName')}*`"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4">{{ $t("listing.companyDetails") }}</h5>
            <div>
              <button
                @click="showCompanyDetails = !showCompanyDetails"
                class="btn btn-outline-main px-5"
              >
                {{ $t("listing.open") }}
                <img
                  src="../../assets/icons/open.svg"
                  class="mr-1"
                  :alt="$t('listing.open')"
                />
              </button>
            </div>
          </div>
          <div class="row" v-if="showCompanyDetails">
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company name"
                v-model="formData.company_name"
                :placeholder="`${$t('listing.companyName')}`"
                rules=""
              />
            </div>
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company address 1"
                v-model="formData.company_address_1"
                :placeholder="`${$t('listing.address1')}`"
                rules=""
              />
            </div>
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company address 2"
                v-model="formData.company_address_2"
                :placeholder="`${$t('listing.address2')}`"
                rules=""
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="company city"
                v-model="formData.company_city"
                :placeholder="`${$t('listing.city')}`"
                rules=""
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="company post code"
                v-model="formData.company_post_code"
                :placeholder="`${$t('listing.postCode')}`"
                rules=""
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                type="country"
                name="company country"
                v-model="formData.company_country"
                :placeholder="`${$t('listing.country')}`"
                rules=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4">{{ $t("listing.specialRequest") }}</h5>
            <div>
              <button
                @click="showSpecialRequest = !showSpecialRequest"
                class="btn btn-outline-main px-5"
              >
                {{ $t("listing.open") }}
                <img
                  src="../../assets/icons/open.svg"
                  class="mr-1"
                  :alt="$t('listing.open')"
                />
              </button>
            </div>
          </div>
          <div class="row" v-if="showSpecialRequest">
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="special request"
                type="textarea"
                v-model="formData.special_request"
                :placeholder="`${$t('listing.specialRequestOrWish')}`"
                rules=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-right">
        <PrimaryButton
          class="ml-auto mt-3"
          :loading="pending"
          :text="$t('listing.sendRequest')"
          @click="submit"
        />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import HotelDatepicker from "hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";

export default {
  name: "BookingRequestForm",
  props: {
    property: {
      type: Object,
      required: true,
    },
    propertyInfo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showCalendar: false,
      datepicker: null,
      calendarValue: "",
      showCompanyDetails: false,
      showSpecialRequest: false,
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        post_code: "",
        city: "",
        guest_first_name: "",
        guest_last_name: "",
        check_in: "",
        check_out: "",
        guests: "",
        company_name: "",
        company_address_1: "",
        company_address_2: "",
        company_city: "",
        company_post_code: "",
        company_country: "",
        special_request: "",
      },
    };
  },
  computed: {
    ...mapState("booking", ["pending"]),
  },
  mounted() {
    document.addEventListener("click", (event) => {
      const calendar = document.querySelector(".datepicker");
      const checkIn = document.querySelector(".check-in");
      const checkOut = document.querySelector(".check-out");
      if (
        calendar &&
        !calendar.contains(event.target) &&
        !checkIn.contains(event.target) &&
        !checkOut.contains(event.target)
      ) {
        this.closeCalendar();
      }
    });
  },
  methods: {
    ...mapActions("booking", ["initiateBookingOnRequest"]),
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$refs.bookerDetails.scrollIntoView({ behavior: "smooth" });
        return;
      }

      const language = this.propertyInfo.languages.find(
        ({ isMain }) => !!isMain
      );

      let email = this.property.emails.find(
        ({ emailType }) => emailType.code === "primary"
      );
      if (email) {
        email = email.email;
      } else {
        email = this.property.emails[0]?.email;
      }

      if (email) {
        await this.initiateBookingOnRequest({
          ...this.formData,
          property_name: this.property.name,
          property_email: email,
          property_address: this.propertyInfo.addresses.length
            ? this.propertyInfo.addresses[0]?.addressLine
            : "N/A",
          property_phone: this.property.phones.length
            ? this.property.phones[0]?.phoneNumber
            : "N/A",
          property_language: language ? language.language?.code || "en" : "en",
        });
        this.$router.push({
          path: "/reservation_success",
          query: {
            type: "C",
            booker: this.formData.email,
            propertyId: this.property.id,
          },
        });
      }
    },
    toggleCalendar() {
      if (this.datepicker) {
        this.closeCalendar();
      } else {
        this.openCalendar();
      }
    },
    openCalendar() {
      this.showCalendar = true;
      setTimeout(() => this.initDataPicker(), 10);
    },
    closeCalendar() {
      if (this.datepicker) {
        this.datepicker.destroy();
      }
      this.datepicker = null;
      this.showCalendar = "";
    },
    initDataPicker() {
      if (this.datepicker) {
        this.datepicker.destroy();
      }
      this.datepicker = new HotelDatepicker(
        document.getElementById("datePicker"),
        {
          inline: true,
          clearButton: false,
          showTopbar: false,
          moveBothMonths: true,
        }
      );
    },
  },
  watch: {
    datepicker: {
      handler(datepicker) {
        if (datepicker) {
          const range = datepicker.getValue();
          if (range) {
            this.calendarValue = range;
            const dates = range.split(" - ");
            this.formData.check_in = moment(dates[0]).format("YYYY-MM-DD");
            this.formData.check_out = moment(dates[1]).format("YYYY-MM-DD");
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
