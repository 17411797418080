<template>
  <button
    @click="$emit('click')"
    class="btn btn-main px-4 d-flex align-items-center justify-content-center"
    :class="{ 'w-100': full, invert: invert }"
    :disabled="disabled || loading"
  >
    <slot>{{ text }}</slot>
    <b-spinner
      v-if="loading"
      class="button-spinner"
      label="Spinning"
    ></b-spinner>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.button-spinner {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-width: 2px;
}
</style>
