export const handleApiError = (error) => {
  const status = error.response?.status;
  const data = error.response?.data;
  let err = error;
  const message = data?.message || 'An error occurred';
  switch (status) {
    // case 500:
    //   err = new Error(data?.message);
    //   break;
    default:
      err = new Error(message);
  }
  return err;
};