<template>
  <div
    class="bookProperty"
    :class="{ relative: !stickToBottom, sticky: stickToBottom }"
  >
    <div :class="{ 'container-fluid plr-80': stickToBottom }">
      <div class="row">
        <div class="offset-md-6 col-md-6">
          <div class="d-md-flex d-none justify-content-end">
            <div class="px-3 border-right mr-5">
              {{ totalRooms }} {{ $t('property.roomOrRooms') }} <br />
              <template v-if="type === 'A'"
                >{{ totalExtras }} {{ $t('property.extraOrExtras') }}</template
              >
            </div>
            <div class="px-4 ml-5">
              <h3>{{ currencyCode }} {{ totalAmount }}</h3>
              <p>{{ $t('property.totalPrice') }}<span>({{ $t('property.vatIncluded') }})</span></p>
            </div>
            <div class="text-right border-left pl-4">
              <PrimaryButton
                class="mt-3"
                @click="$emit('proceed')"
                :text="buttonText || $t('property.bookNow')"
                :loading="disabled"
              />
              <button
                v-if="canClear"
                @click="$emit('clear')"
                class="btn px-0 text-underline small"
              >
                <u>{{ $t('property.clearSelection') }}</u>
              </button>
            </div>
          </div>
          <div class="d-md-none d-block justify-content-end">
            <div class="d-flex justify-content-between text-right">
              <div>{{ totalRooms }} {{ $t('property.roomOrRooms') }}</div>
              <div v-if="type === 'A'">{{ totalExtras }} {{ $t('property.extraOrExtras') }}</div>
            </div>
            <div class="divider my-2"></div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b>{{ $t('property.totalPrice') }}</b> <br />
                ({{ $t('property.vatIncluded') }})
              </div>
              <div>
                <h3>
                  <b>{{ currencyCode }} {{ totalAmount }}</b>
                </h3>
              </div>
            </div>
            <PrimaryButton
              class="mt-3"
              @click="$emit('proceed')"
              :text="buttonText || $t('property.bookNow')"
              full
              :invert="stickToBottom"
              :loading="disabled"
            />
            <div class="text-right">
              <button
                v-if="canClear"
                @click="$emit('clear')"
                class="btn px-0 text-underline small text-white"
              >
                <u>{{ $t('property.clearSelection') }}</u>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingCheckout",
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    cart: {
      type: Array,
      required: true,
    },
    extras: {
      type: Array,
      required: true,
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stickToBottom() {
      return this.sticky || window.innerWidth <= 600;
    },
    totalAmount() {
      if (this.type === "A") {
        let total = 0;
        this.cart.forEach((item) => {
          item.guests.forEach((guest) => {
            total += parseFloat(guest.price);
            guest.variations.forEach(({ quantity, totalAmount }) => {
              total += quantity * totalAmount;
            });
          });
        });
        const extraTotal = this.extras.reduce((accumulator, item) => {
          return accumulator + item.price * item.quantity;
        }, 0);
        return (parseFloat(total) + parseFloat(extraTotal)).toFixed(2);
      }
      return this.cart
        .reduce((accumulator, item) => {
          return accumulator + item.price * item.quantity;
        }, 0)
        .toFixed(2);
    },
    currencyCode() {
      return this.cart[0].currencyCode;
    },
    totalRooms() {
      if (this.type === "A") {
        return this.cart.reduce((accumulator, item) => {
          return accumulator + item.guests.length;
        }, 0);
      }
      return this.cart.reduce((accumulator, item) => {
        return accumulator + item.guests;
      }, 0);
    },
    totalExtras() {
      if (this.type === "A") {
        return this.extras.reduce((accumulator, item) => {
          return accumulator + item.quantity;
        }, 0);
      }
      return "";
    },
  },
};
</script>

<style>
.divider {
  width: 100%;
  height: 2px;
  background: white;
}
</style>
