<template>
  <div class="my-1">
    <ValidationProvider rules="required|accepted" v-slot="{ errors }">
      <div :class="[{ 'input-error': errors.length }]">
        <input
          name="terms and conditions"
          type="checkbox"
          class="mr-1"
          v-model="termsAccepted"
        />
        {{ $t("reservation.attestationMessage") }}
        <a href="#" class="text-main">{{
          $t("reservation.termsAndCondition")
        }}</a>
        {{ $t("reservation.andThe") }}
        <a href="#" class="text-main">{{ $t("reservation.privacyPolicy") }}</a>
      </div>
      <span v-if="errors.length" class="text-danger small">{{
        errors[0]
      }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "TermsAndPolicies",
  data() {
    return {
      termsAccepted: "",
    };
  },
  methods: {},
};
</script>
