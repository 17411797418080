import ApiService from "@/services";
import { handleApiError } from "../helpers";

const state = {
  pending: false,
};

const getters = {};

const mutations = {
  setPending(state, value) {
    state.pending = value;
  },
};

const actions = {
  async initiateBookingOnRequest({ commit }, payload) {
    commit("setPending", true);
    await ApiService.post("booking/request", payload);
    commit("setPending", false);
  },
  async getPaymentCardForm(_, payload) {
    return await ApiService.get("pci-booking/card-form", payload);
  },
  async processCultswitchBooking(_, payload) {
    return await ApiService.post("booking/cultswitch/process", payload);
  },
  async processEhotelBooking(_, payload) {
    return await ApiService.post("booking/ehotel/process", payload);
  },
  async cancelBooking(_, payload) {
    try {
      await ApiService.post("booking/cancel", payload);
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getCancelPolicy(_, reservationId) {
    try {
      const { data } = await ApiService.get(
        `booking/cancel-policy/${reservationId}`
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
