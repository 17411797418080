<template>
  <div>
    <div class="card my-4">
      <div class="card-body px-md-5 shadow-sm">
        <div class="row">
          <div class="col-md-6 py-2">
            <h5 class="mb-4">{{ $t("reservation.yourReservation") }}</h5>
            <table class="table custom-table">
              <tr>
                <td>{{ $t("reservation.totalNumberOfRooms") }}</td>
                <td class="text-right">{{ roomsCounts }}</td>
              </tr>
              <tr>
                <td>{{ $t("reservation.totalNumberOfPersons") }}</td>
                <td class="text-right">{{ guestsCounts }}</td>
              </tr>
              <tr>
                <td>{{ $t("reservation.nights") }}</td>
                <td class="text-right">{{ nightsCount }}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6 py-2">
            <h5 class="mb-4">{{ $t("reservation.travelPeriod") }}</h5>
            <table class="table custom-table">
              <tr>
                <td>{{ $t("reservation.checkIn") }}</td>
                <td class="text-right">{{ checkoutData.date.checkIn }}</td>
              </tr>
              <tr>
                <td>{{ $t("reservation.checkOut") }}</td>
                <td class="text-right">{{ checkoutData.date.checkOut }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-4">
      <div class="card-body px-md-5 shadow-sm">
        <div class="d-md-block d-none">
          <table class="table">
            <tr>
              <th>{{ $t("reservation.noOfRooms") }}</th>
              <th>{{ $t("reservation.totalPrice") }}</th>
              <th class="text-center">{{ $t("property.personPerRoom") }}</th>
              <th>{{ $t("reservation.mealType") }}</th>
              <th>{{ $t("reservation.pricePerRoom") }}</th>
              <th>{{ $t("reservation.total") }}</th>
              <th>{{ $t("reservation.remove") }}</th>
            </tr>
            <template v-for="(item, index) in checkoutData.cart">
              <tr
                v-for="(guest, idx) in item.guests"
                :key="`${item.id}-${idx}`"
              >
                <td>{{ guest.guestCount }}x</td>
                <td class="text-main">
                  <span>{{ item.product.productDescription.name }}</span
                  ><br />
                  <div>
                    <span
                      role="button"
                      v-b-popover.bottom.hover="
                        item.product.productDescription.description
                      "
                      >{{ $t("property.rateDetails") }}</span
                    >
                    | <span>{{ $t("property.cancellationPolicy") }}</span>
                  </div>
                </td>
                <td class="text-center">
                  <img
                    v-for="i in totalPersonsCount(guest)"
                    :key="i"
                    src="../../assets/icons/person.svg"
                    class="mr-1"
                    alt="person"
                  />
                </td>
                <td>
                  <template v-if="item.product.mealsIncluded">
                    <span>{{ item.product.mealsIncluded }}</span>
                  </template>
                  <template v-else>
                    {{ $t("property.noMealIncluded") }}
                  </template>
                </td>
                <td>
                  <span class="text-main"
                    >{{ item.product.currencyCode }}
                    {{ getRoomTotal(guest) }}</span
                  >
                </td>
                <td>
                  <span class="text-main"
                    ><b
                      >{{ item.product.currencyCode }}
                      {{ getRoomTotal(guest) }}</b
                    ></span
                  >
                </td>
                <td class="text-center">
                  <img
                    @click="removeRoom(index, idx)"
                    src="../../assets/icons/trash.svg"
                    class="mr-1 cursor-pointer"
                    alt="trash"
                  />
                </td>
              </tr>
            </template>
          </table>
        </div>
        <div class="d-md-none d-block">
          <template v-for="(item, index) in checkoutData.cart">
            <table
              class="table mb-4"
              v-for="(guest, idx) in item.guests"
              :key="`${item.id}-${idx}`"
            >
              <tr>
                <td class="text-main">
                  <span>{{ item.product.productDescription.name }}</span
                  ><br />
                  <div>
                    <span
                      role="button"
                      v-b-popover.bottom.hover="
                        item.product.productDescription.description
                      "
                      >{{ $t("property.rateDetails") }}</span
                    >
                    | <span>{{ $t("property.cancellationPolicy") }}</span>
                  </div>
                </td>
                <td class="text-right">
                  <img
                    @click="removeRoom(index, idx)"
                    src="../../assets/icons/trash.svg"
                    class="mr-1 cursor-pointer"
                    alt="trash"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("reservation.noOfRooms") }}</td>
                <td class="text-right">{{ guest.guestCount }}x</td>
              </tr>
              <tr>
                <td>{{ $t("property.persons") }}</td>
                <td class="text-right">
                  <img
                    v-for="i in totalPersonsCount(guest)"
                    :key="i"
                    src="../../assets/icons/person.svg"
                    class="mr-1"
                    alt="person"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("reservation.mealType") }}</td>
                <td class="text-right">
                  <template v-if="item.product.mealsIncluded">
                    <span>{{ item.product.mealsIncluded }}</span>
                  </template>
                  <template v-else>
                    {{ $t("property.noMealIncluded") }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{ $t("reservation.pricePerRoom") }}</td>
                <td class="text-right">
                  <span class="text-main"
                    >{{ item.product.currencyCode }}
                    {{ getRoomTotal(guest) }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>{{ $t("reservation.total") }}</td>
                <td class="text-right">
                  <span class="text-main"
                    ><b
                      >{{ item.product.currencyCode }}
                      {{ getRoomTotal(guest) }}</b
                    ></span
                  >
                </td>
              </tr>
            </table>
          </template>
        </div>
      </div>
    </div>

    <div
      class="card my-4"
      v-if="checkoutData.extras && checkoutData.extras.length"
    >
      <div class="card-body px-md-5 shadow-sm">
        <div class="d-md-block d-none">
          <table class="table">
            <tr>
              <th>{{ $t("reservation.noOfExtras") }}</th>
              <th>{{ $t("reservation.extra") }}</th>
              <th>{{ $t("reservation.pricePerService") }}</th>
              <th>{{ $t("reservation.total") }}</th>
              <th class="text-center">{{ $t("reservation.remove") }}</th>
            </tr>
            <tr v-for="item in checkoutData.extras" :key="item.id">
              <td>{{ item.quantity }}x</td>
              <td>
                <span class="text-main">{{
                  item.extra.productDescription.name
                }}</span>
              </td>
              <td>
                <span class="text-main"
                  >{{ item.extra.currencyCode }} {{ item.price }}</span
                >
              </td>
              <td>
                <span class="text-main"
                  ><b
                    >{{ item.extra.currencyCode }}
                    {{ item.price * item.quantity }}</b
                  ></span
                >
              </td>
              <td class="text-center">
                <img
                  @click="removeExtraAndSave(item)"
                  src="../../assets/icons/trash.svg"
                  class="mr-1 cursor-pointer"
                  alt="trash"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="d-md-none d-block">
          <table
            class="table"
            v-for="item in checkoutData.extras"
            :key="item.id"
          >
            <tr>
              <td>
                <span class="text-main">{{
                  item.extra.productDescription.name
                }}</span>
              </td>
              <td class="text-right">
                <img
                  @click="removeExtraAndSave(item)"
                  src="../../assets/icons/trash.svg"
                  class="mr-1 cursor-pointer"
                  alt="trash"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.noOfExtras") }}</td>
              <td class="text-right">{{ item.quantity }}x</td>
            </tr>
            <tr>
              <td>{{ $t("reservation.pricePerService") }}</td>
              <td class="text-right">
                <span class="text-main"
                  >{{ item.extra.currencyCode }} {{ item.price }}</span
                >
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.total") }}</td>
              <td class="text-right">
                <span class="text-main"
                  ><b
                    >{{ item.extra.currencyCode }}
                    {{ item.price * item.quantity }}</b
                  ></span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <ValidationObserver ref="observer">
      <div class="card my-4" ref="bookerDetails">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4">{{ $t("reservation.yourDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="first name"
                v-model="bookerDetails.firstName"
                :placeholder="$t('reservation.placeholderFirstName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="last name"
                v-model="bookerDetails.lastName"
                :placeholder="$t('reservation.placeholderLastName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="email"
                type="email"
                v-model="bookerDetails.email"
                :placeholder="$t('reservation.placeholderEmail')"
                rules="required|email"
              />
            </div>
            <div class="col-md-5 my-3 no-border">
              <ValidatedInput
                name="phone"
                type="tel"
                :placeholder="$t('reservation.placeholderPhone')"
                v-model="bookerDetails.phone"
                rules="required"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="post code"
                v-model="bookerDetails.postCode"
                :placeholder="$t('reservation.placeholderPostCode')"
                rules="required"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="city"
                v-model="bookerDetails.city"
                :placeholder="$t('reservation.placeholderCity')"
                rules="required"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="Country"
                type="country"
                v-model="bookerDetails.country"
                placeholder="Country*"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4">{{ $t("reservation.guestDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest first name"
                v-model="guestDetails.firstName"
                :placeholder="$t('reservation.placeholderFirstName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest last name"
                v-model="guestDetails.lastName"
                :placeholder="$t('reservation.placeholderLastName')"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4">
              {{ $t("reservation.companyDetails") }} ({{
                $t("reservation.optional")
              }})
            </h5>
            <div>
              <button
                @click="showCompanyDetails = !showCompanyDetails"
                class="btn btn-outline-main px-md-5 px-3"
              >
                {{ $t("listing.open") }}
                <img
                  src="../../assets/icons/open.svg"
                  class="mr-1"
                  alt="open"
                />
              </button>
            </div>
          </div>
          <div class="row" v-if="showCompanyDetails">
            <div class="col-md-10 my-3">
              <input
                class="form-control no-border"
                type="text"
                v-model="companyDetails.companyName"
                :placeholder="$t('reservation.placeholderCompanyNameOpt')"
              />
            </div>
            <div class="col-md-10 my-3">
              <input
                class="form-control no-border"
                type="text"
                v-model="companyDetails.address1"
                :placeholder="$t('reservation.placeholderAddress1')"
              />
            </div>
            <div class="col-md-10 my-3">
              <input
                class="form-control no-border"
                type="text"
                :placeholder="$t('reservation.placeholderAddress2')"
                v-model="companyDetails.address2"
              />
            </div>
            <div class="col-md-3 my-3">
              <input
                class="form-control no-border"
                type="text"
                v-model="companyDetails.city"
                :placeholder="$t('reservation.placeholderCityOpt')"
              />
            </div>
            <div class="col-md-3 my-3">
              <input
                class="form-control no-border"
                type="text"
                v-model="companyDetails.postCode"
                :placeholder="$t('reservation.placeholderPostCodeOpt')"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="Country"
                type="country"
                v-model="companyDetails.country"
                placeholder="Country"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4">{{ $t("reservation.specialRequests") }}</h5>
            <div>
              <button
                @click="showSpecialRequest = !showSpecialRequest"
                class="btn btn-outline-main px-md-5 px-3"
              >
                {{ $t("listing.open") }}
                <img
                  src="../../assets/icons/open.svg"
                  class="mr-1"
                  alt="open"
                />
              </button>
            </div>
          </div>
          <div class="row" v-if="showSpecialRequest">
            <div class="col-md-10 my-3">
              <ValidatedInput
                type="textarea"
                v-model="specialRequest"
                placeholder="Do you have any special requests / wishes?"
                rules=""
              />
            </div>
          </div>
        </div>
      </div>

      <CreditCardField
        @completeBooking="$emit('completeBooking', $event)"
        @formInvalid="$emit('formInvalid')"
        v-if="collectCardDetails"
      />

      <hr />

      <TermsAndPolicies />
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
import CreditCardField from "./CreditCardField.vue";
import TermsAndPolicies from "./TermsAndPolicies.vue";
export default {
  name: "CultswitchCheckout",
  props: {
    checkoutData: {
      type: Object,
      required: true,
    },
    collectCardDetails: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CreditCardField,
    TermsAndPolicies,
  },
  data() {
    return {
      bookerDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        postCode: "",
        city: "",
        country: "",
      },
      guestDetails: {
        firstName: "",
        lastName: "",
      },
      companyDetails: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        postCode: "",
        country: "",
      },
      specialRequest: "",
      showCompanyDetails: false,
      showSpecialRequest: false,
    };
  },
  computed: {
    roomsCounts() {
      return this.checkoutData.cart.reduce((accumulator, item) => {
        return accumulator + item.guests.length;
      }, 0);
    },
    guestsCounts() {
      let guests = 0;
      this.checkoutData.cart.forEach((item) => {
        item.guests.forEach(({ guestCount }) => (guests += guestCount));
      });
      return guests;
    },
    nightsCount() {
      const { checkIn, checkOut } = this.checkoutData.date;
      return moment(checkOut, "YYYY-MM-DD").diff(
        moment(checkIn, "YYYY-MM-DD"),
        "days"
      );
    },
  },
  methods: {
    removeRoom(cartIndex, guestIdx) {
      this.checkoutData.cart[cartIndex].guests.splice(guestIdx, 1);
      if (!this.checkoutData.cart[cartIndex].guests.length) {
        this.checkoutData.cart.splice(cartIndex, 1);
      }
      const { id } = this.$route.params;
      if (this.checkoutData.cart.length === 0) {
        sessionStorage.removeItem(`checkoutData-${id}`);
        this.$router.push(`/listing/${id}`);
      } else {
        sessionStorage.setItem(
          `checkoutData-${id}`,
          JSON.stringify(this.checkoutData)
        );
      }
    },
    removeExtraAndSave(extra) {
      this.removeExtra(extra);
      const { id } = this.$route.params;
      sessionStorage.setItem(
        `checkoutData-${id}`,
        JSON.stringify(this.checkoutData)
      );
    },
    getData() {
      const { bookerDetails, guestDetails, companyDetails, specialRequest } =
        this;
      return {
        booker: bookerDetails,
        guest: guestDetails,
        company: companyDetails,
        note: specialRequest,
      };
    },
    totalPersonsCount(item) {
      let count = item.guestCount;
      item.variations.forEach((v) => {
        count += v.quantity;
      });
      return count;
    },
    getRoomTotal(item) {
      return item.variations.reduce((sum, v) => {
        return parseFloat(sum) + parseFloat(v.totalAmount * v.quantity);
      }, item.price);
    },
  },
};
</script>

<style scoped>
table tr td {
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  h5 {
    font-size: 16px;
  }
  table tr td {
    font-size: 14px;
  }
}
</style>
