<template>
  <div class="row custom-calender hidden">
    <div class="col-md-4 my-1">
      <label for="">{{ $t("listing.checkIn") }}</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          @click="toggleCalendar('Checkin')"
          class="input check-in has-icon"
          type="text"
          :value="date.checkIn"
          :disabled="disabled"
        />
        <input
          v-if="showCalendar === 'mobileCheckin'"
          type="text"
          v-model="calendarValue"
          id="datePicker"
          style="opacity: 0; height: 0"
        />
      </div>
    </div>
    <div class="col-md-4 my-1">
      <label for="">{{ $t("listing.checkOut") }}</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          @click="toggleCalendar('Checkout')"
          class="input check-out has-icon"
          type="text"
          :value="date.checkOut"
          :disabled="disabled"
        />
        <input
          v-if="showCalendar === 'mobileCheckout'"
          type="text"
          v-model="calendarValue"
          id="datePicker"
          style="opacity: 0; height: 0"
        />
      </div>
    </div>
    <div class="col-md-4 mt-auto my-1">
      <PrimaryButton
        @click="searchAvailability"
        :text="$t('listing.search')"
        full
        :loading="disabled"
        class="py-2 mt-3"
      />
    </div>
    <div>
      <input
        v-if="showCalendar === 'desktop'"
        type="text"
        v-model="calendarValue"
        id="datePicker"
        style="opacity: 0; height: 0"
      />
    </div>
    <template v-if="!promoDisabled">
      <div
        v-if="!showPromoField"
        class="col-md-12 mt-3 promo cursor-pointer"
        @click="showPromoField = true"
      >
        <img src="../../assets/icons/promo.svg" class="mr-1" alt="promo" />
        <span>{{ $t("property.havePromo") }}</span>
      </div>
      <div v-else class="col-md-4 mt-3">
        <div class="calendar-input-group">
          <span class="icon promo">
            <img src="../../assets/icons/promo.svg" alt="calendar" />
          </span>
          <b-form-input
            class="input check-out has-icon"
            type="text"
            :placeholder="$t('property.promoCode')"
            v-model="promoCode"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import HotelDatepicker from "hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
export default {
  name: "BookingEngineDateSelector",
  props: {
    checkIn: {
      type: String,
      required: false,
    },
    checkOut: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formatted: {
      type: Boolean,
      default: false,
    },
    promoDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPromoField: false,
      showCalendar: false,
      datepicker: null,
      showCalendarPrice: false,
      promoCode: "",
      calendarValue: "",
      date: {
        checkIn: null,
        checkOut: null,
      },
    };
  },
  mounted() {
    document.addEventListener("click", (event) => {
      const calendar = document.querySelector(".datepicker");
      const checkIn = document.querySelector(".check-in");
      const checkOut = document.querySelector(".check-out");
      if (
        calendar &&
        !calendar.contains(event.target) &&
        !checkIn.contains(event.target) &&
        !checkOut.contains(event.target)
      ) {
        this.closeCalendar();
      }
    });
    setTimeout(() => {
      const { checkIn, checkOut } = this;
      if (checkIn && checkOut) {
        this.date.checkIn = this.formatted
          ? moment(checkIn, "YYYY-MM-DD").format("DD MMM YYYY")
          : moment(checkIn, "DD-MM-YYYY").format("DD MMM YYYY");
        this.date.checkOut = this.formatted
          ? moment(checkOut, "YYYY-MM-DD").format("DD MMM YYYY")
          : moment(checkOut, "DD-MM-YYYY").format("DD MMM YYYY");
        this.calendarValue =
          moment(checkIn, "DD-MM-YYYY").format("YYYY-MM-DD") +
          " - " +
          moment(checkOut, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    }, 100);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    datepicker: {
      handler(datepicker) {
        if (datepicker) {
          const range = datepicker.getValue();
          if (range) {
            this.calendarValue = range;
            const dates = range.split(" - ");
            this.date.checkIn = moment(dates[0]).format("DD MMM YYYY");
            this.date.checkOut = moment(dates[1]).format("DD MMM YYYY");
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleCalendar(mode) {
      if (this.datepicker) {
        this.closeCalendar();
      } else {
        this.openCalendar(mode);
      }
    },
    openCalendar(mode) {
      if (window.innerWidth > 600) {
        this.showCalendar = "desktop";
      } else {
        this.showCalendar = `mobile${mode}`;
      }
      setTimeout(() => this.initDataPicker(), 10);
    },
    closeCalendar() {
      this.datepicker.destroy();
      this.datepicker = null;
      this.showCalendar = false;
    },
    searchAvailability() {
      const { checkIn, checkOut } = this.date;
      if (checkIn && checkOut) {
        this.$emit("search", {
          checkIn: moment(checkIn, "DD MMM YYYY").format("YYYY-MM-DD"),
          checkOut: moment(checkOut, "DD MMM YYYY").format("YYYY-MM-DD"),
          promoCode: this.promoCode,
        });
      }
    },
    initDataPicker() {
      if (this.datepicker) {
        this.datepicker.destroy();
      }
      const { showCalendarPrice } = this;
      this.datepicker = new HotelDatepicker(
        document.getElementById("datePicker"),
        {
          inline: true,
          clearButton: false,
          showTopbar: false,
          moveBothMonths: true,
          disabledDates: ["2024-12-20", "2024-12-21"],
          extraDayText: function (date, attributes) {
            const hasPrice = moment(date).isSameOrAfter(new Date());
            if (
              showCalendarPrice &&
              hasPrice &&
              attributes.class.includes("datepicker__month-day--visibleMonth")
            ) {
              return "<br> $150";
            }
          },
        }
      );

      if (showCalendarPrice) {
        document.querySelector(".datepicker").classList.add("has_price");
      }

      const priceControl = document.createElement("div");
      priceControl.innerHTML = `
      <div class="datepicker-price-control">
        <div class="d-flex justify-content-end">
          <div class="mr-3">
            ${this.$t("property.showPrices")} <br>
            <span class="price-currency small text-main mt-2">(${this.$t(
              "property.allPriceIn",
              { currency: "EUR" }
            )})</span>
          </div>
          <div class="custom-control custom-switch" style="scale: 1.5">
            <input type="checkbox" ${
              showCalendarPrice ? "checked" : ""
            } class="custom-control-input" id="priceControlSwitch">
            <label class="custom-control-label" for="priceControlSwitch"></label>
          </div>
        </div>
      </div>
      `;

      document.querySelector(".datepicker").appendChild(priceControl);

      const priceControlSwitch = document.querySelector("#priceControlSwitch");
      priceControlSwitch.addEventListener("change", () => {
        this.showCalendarPrice = !showCalendarPrice;
        this.initDataPicker();
      });
    },
  },
};
</script>
